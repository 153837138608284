import * as React from "react"
import { addPropertyControls, ControlType } from "framer"
import {
    emptyStateStyle,
    stateParagraphStyle,
    stateTitleStyle,
} from "https://framer.com/m/framer/integrations-styles.js@0.2.0"

// Destructuring React to use hooks
const { useEffect, useState } = React

/**
 * HUBSPOT
 *
 * @framerIntrinsicWidth 480
 * @framerIntrinsicHeight 300
 *
 * @framerDisableUnlink
 *
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight any
 */
export default function CustomHubSpotForm({
    portalId,
    formId,
    accessToken,
    region = "eu1",
}) {
    const elementId = `hubspot_form_${formId}`
    const [showLoading, setShowLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (!portalId || !formId || !accessToken) return

        const fetchFormData = async () => {
            try {
                const response = await fetch(
                    `https://api.hubapi.com/forms/v2/forms/${formId}?portalId=${portalId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "Content-Type": "application/json",
                        },
                    }
                )
                if (!response.ok) {
                    throw new Error(
                        `Error fetching form data: ${response.statusText}`
                    )
                }
                const formData = await response.json()
                console.log("Form Data:", formData)
                setShowLoading(false)
            } catch (error) {
                console.error("Error fetching form data:", error)
                setError(error.message)
                setShowLoading(false)
            }
        }

        fetchFormData()
    }, [portalId, formId, accessToken])

    useEffect(() => {
        const cleanup = () => {
            const element = document.querySelector(`#${elementId}`)
            if (element) element.innerHTML = ""
        }

        const handleScriptLoad = () => {
            window.hbspt.forms.create({
                portalId,
                formId,
                target: `#${elementId}`,
                formInstanceId: formId,
                region,
                cssClass: "", // Apply custom styling by removing 'stacked' class
            })
        }

        if (window.hbspt) {
            handleScriptLoad()
            return cleanup
        }

        const script = document.createElement("script")
        script.src = `https://js-${region}.hsforms.net/forms/shell.js`
        script.onload = handleScriptLoad
        document.body.appendChild(script)

        return cleanup
    }, [portalId, formId, region])

    if (!portalId || !formId || showLoading) {
        return (
            <div style={{ ...emptyStateStyle, width: "100%", height: "100%" }}>
                <h1 style={{ ...stateTitleStyle }}>Custom Hubspot Form</h1>
                <p style={{ ...stateParagraphStyle }}>
                    Set a portal ID, form ID, and access token in the
                    Properties.
                </p>
                {showLoading && (
                    <svg
                        id="loading"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                    >
                        <circle
                            cx="50"
                            cy="50"
                            fill="none"
                            stroke="#85a2b6"
                            strokeWidth="6"
                            r="35"
                            strokeDasharray="164.93361431346415 56.97787143782138"
                        >
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                repeatCount="indefinite"
                                dur="1s"
                                values="0 50 50;360 50 50"
                                keyTimes="0;1"
                            />
                        </circle>
                    </svg>
                )}
                {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
        )
    }

    return (
        <div>
            <div id={elementId} style={formContainer} />
            <style>{`
                #${elementId} fieldset {
                    max-width: 100%!important;
                }
                #${elementId} .hs-form-field {
                    display: block;
                    width: 100%;
                }        
                #${elementId} .hs_firstname,
                #${elementId} .hs_lastname,
                #${elementId} .hs_email,
                #${elementId} .hs_phone {
                    display: inline-block;
                    width: calc(50% - 5px);
                    margin-right: 10px;
                    vertical-align: top;
                }
                #${elementId} .hs_lastname,
                #${elementId} .hs_phone {
                    margin-right: 0;
                }
                #${elementId} input:not([type='checkbox']),
                #${elementId} .input {
                    width: 100%;
                    margin: 0;     
                }  
                #${elementId} ul.inputs-list {
                    list-style: none;
                    padding: 0;
                }
                #${elementId} label {
                    display: block;
                    margin-bottom: 8px;
                }     
                #${elementId} .hs-form-required {
                    color: red;
                    padding-left: 5px;
                }
            `}</style>
        </div>
    )
}

const formContainer = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
}

addPropertyControls(CustomHubSpotForm, {
    portalId: {
        title: "Portal ID",
        type: ControlType.String,
        placeholder: "Enter your HubSpot Portal ID",
    },
    formId: {
        title: "Form ID",
        type: ControlType.String,
        placeholder: "Enter your HubSpot Form ID",
    },
    accessToken: {
        title: "Access Token",
        type: ControlType.String,
        placeholder: "Enter your HubSpot Access Token",
    },
})
